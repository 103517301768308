.footer-container {
    height: auto;
    width: 100%;
    text-align: center;
    font-family: "Arapona Light";
    margin-top: var(--margin-top-large);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: auto;
    width: 60%;
}

.logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.footer_logo {
    width: var(--footer-logo-size);
}

.instagram_logo {
    width: var(--instagram-logo-size);
    height: auto;
}

.form-title {
    color: black;
    font-size: var(--font-size-map);
}

.form {
    height: auto;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px;
    border: 3px solid var(--color-raja-red);
    border-radius: 10px;
    background-color: var(--color-cream);
    font-family: "Arapona Light";
}

.inputBox {
    width: 90%;
    height: 20px;
    margin-top: 20px;
    font-family: "Arapona Light";
    font-size: var(--font-size-base);
    color: black;
    border: none;
    border-bottom: 1px solid black;
    background-color: rgba(11, 11, 10, 0);
}

.instagram-container {
    /* margin-top: var(--margin-top-small); */
    margin-top: 0;
}

.instagram_logo{
    margin-top: 10px;
}

.follow-text {
    font-family: "Arapona Light";
    color: var(--color-cream);
    font-size: 18px;
    text-align: center;
    margin: 0;
}

.submitButton {
    height: 40px;
    width: 150px;
    background-color: var(--color-raja-red);
    border-radius: 2px;
    margin-top: var(--margin-top-small);
    color: var(--color-cream);
    font-family: "Arapona Regular";
    font-size: var(--font-size-button);
    border: none;
}

.copyright {
    margin-top: var(--margin-top-large);
    color: var(--color-cream);
    margin-bottom: var(--margin-top-medium);
}

@media only screen and (max-width: 1024px) {
    .footer-span {
        flex-direction: column;
        justify-content: space-evenly;
        width: 80%;
    }

    .logo-container {
        margin-top: var(--margin-top-large);
    }

    .instagram-container {
        margin-top: var(--margin-top-small);
    }
    
    .instagram_logo{
        margin-top: var(--margin-top-small);
    }
}
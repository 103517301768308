.locator-container {
    height: 600px;
    width: 80%;
    max-width: 800px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: var(--margin-top-medium);
}

.map-container {
    width: 100%;
    height: 100%;
    border: 3px solid var(--color-raja-red);
    margin-top: var(--margin-top-small);
}

.locator-header {
    width: 80%;
    color: var(--color-cream);
    text-align: center;
    font-family: "Arapona Light";
    font-size: var(--font-size-base);
}

.marker-address {
    height: auto;
    width: auto;
    font-family: "Arapona Light";
    font-size: var(--font-size-map);
}

.marker-title {
    font-family: "Arapona Regular";
    font-size: var(--font-size-base);
    color: var(--color-raja-red)
}

.marker-text {
    width: 100%;
}

.marker-link {
    color: black;
}

.marker-link:active {
    color: var(--color-raja-red);
}

.marker-window {
    padding: 0px 20px 10px 10px;
    text-align: left;
}

.zip-code-input{
    width: 150px;
    height: 100%;
    font-family: "Arapona Light";
    font-size: var(--font-size-button);
    color: black;
    border: 1px solid black;
    background-color: var(--color-cream);
    padding: 5px;
}

.zip-code-form{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 25px;
}

.zip-submit{
    width: auto;
    background-color: var(--color-raja-red);
    color: black;
    border: 1px solid black;
    padding: 5px;
    font-family: "Arapona Regular";
    font-size: var(--font-size-button);
}
.spinner-container {
    width: 100vw;
    height: 100vh;
    /* Ensures the spinner container takes full viewport width and height */
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    /* Positions the spinner container at the top-left corner of the viewport */
    background-image: radial-gradient(circle at center, var(--color-spinner-bg) 0, var(--color-spinner-fg) 80%, var(--color-spinner-fg) 100%);
    z-index: 9999;
    /* Ensures the spinner appears above other content on the page */
}

.spinner {
    width: var(--spinner-size);
    height: var(--spinner-size);
    border: var(--spinner-border-width) solid;
    border-color: var(--color-raja-red) transparent var(--color-raja-red) transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
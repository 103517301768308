.modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
	backdrop-filter: blur(10px); /* Apply blur effect */
	z-index: 1;
}

.modal-container {
  height: auto;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
  border: 2px solid var(--color-cream);
  border-radius: 10px;
  background-color: black;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  z-index: 2;
}

.modal-text {
  font-family: "Arpona Regular";
  font-size: var(--font-size-base);
  color: var(--color-cream);
  text-align: center;
}

.close-button {
  height: 40px;
  width: 150px;
  background-color: var(--color-raja-red);
  border-radius: 2px;
  border: none;
  margin-top: var(--margin-top-small);
  color: var(--color-cream);
  font-family: "Arapona Regular";
  font-size: var(--font-size-button);
}
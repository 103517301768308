.header-container {
    width: 100vw;
    height: auto; /* Adjusted to occupy full viewport height */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: var(--margin-top-large); /* Ensure --margin-top-large is defined */
}

.header-text {
    margin: 0;
    width: 100vw;
    font-family: "Arapona Regular";
    color: var(--color-cream);
    font-size: var(--font-size-header);
    font-weight: bolder;
    text-align: center;
    margin-top: var(--margin-top-large);
}

.spinning-logo {
    width: var(--header-logo-size);
    max-width: var(--logo-max-size); /* Adjusted to use --logo-max-size */
}

.raja-can {
    max-height: 60vh;
    min-height: 460px;
    max-width: var(--raja-can-size);
}

/* Arrow styling and animation */
.scroll-arrow {
    font-size: 2rem;
    color: var(--color-cream);
    animation: blink 10s infinite;
    margin-top: var(--margin-top-medium);
}

/* Blinking animation keyframes */
@keyframes blink {
    0%, 50%, 100% {
        opacity: 0.5;
    }
    25%, 75% {
        opacity: 0;
    }
}

@media only screen and (max-height: 1024px) {
    .header-container {
        margin-top: var(--margin-top-medium);
    }
    .header-text {
        margin-top: var(--margin-top-small);
    }
    /* Arrow styling and animation */
    .scroll-arrow {
        margin-top: var(--margin-top-small);
    }
}

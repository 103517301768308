:root {
  /* Colors */
  --color-cream: #fef7ee;
  --color-dark-gray: #1e1c1a;
  --color-darker-gray: #0b0b0a;
  --color-raja-red: #9a0000;
  --color-spinner-bg: #1e1c1a;
  --color-spinner-fg: #0b0b0a;

  /* Font Sizes */
  --font-size-header: calc(16px + 2vmax);
  --font-size-base: 16pt;
  --font-size-map: 14pt;
  --font-size-button: 12pt;

  /* Loading Spinner */
  --spinner-size: 64px;
  --spinner-border-width: 8px;

  /* Margins */
  --margin-top-large: 100px;
  --margin-top-medium: 50px;
  --margin-top-small: 25px;

  /* Images */
  --raja-can-size: 50vw;
  --footer-logo-size: 150px;
  --header-logo-size: 40vw;
  --logo-max-size: 200px;
  --instagram-logo-size: 50px;
}

@font-face {
  font-family: "Arapona Light";
  src: url("../fonts/Arpona_Light.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Arapona Regular";
  src: url("../fonts/Arpona_Regular.otf") format("opentype");
  font-display: swap;
}

.App {
  width: 100vw;
  height: auto;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.main-container {
  background-image: radial-gradient(circle at center, var(--color-dark-gray) 0, var(--color-darker-gray) 100%);
  width: 100%;
  min-width: 360px; /* Ensures a minimum width for smaller screens */
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
